body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  display:flex;
  justify-content:space-between;
  height: 100px;
}

.book {
  background-color: #eee;     /* 背景色指定 */
  padding:  10px;             /* 余白指定 */
  display: flex;              /* フレックスボックスにする */
  align-items:stretch;        /* 縦の位置指定 */
  font-size: 20px;
  width: 100%;
}

.book_description {
  background-color: #eee;     /* 背景色指定 */
  padding:  10px;             /* 余白指定 */
  display: flex;              /* フレックスボックスにする */
  flex-flow: column;
  width: 50%;              /* 幅指定 */
}
.book_image {
  background-color: #eee;     /* 背景色指定 */
  padding:  10px;             /* 余白指定 */
  display: flex;              /* フレックスボックスにする */
  flex-flow: column;
  width: 50%;              /* 幅指定 */
}

.AiBookLogo {
  margin-right: auto;
}

.wrapper {
  /* background-color: #eee;     背景色指定 */
  padding:  10px;             /* 余白指定 */
  display: flex;              /* フレックスボックスにする */
  align-items:stretch;        /* 縦の位置指定 */
}

.leftDiv {
  padding-left: 50px;
  /* padding: 10px; */
  color:  #000000;               /* 文字色 */
  margin:  10px;              /* 外側の余白 */
  border-radius:  5px;        /* 角丸指定 */
  width: 40%;                 /* 幅指定 */
  flex-flow: column;
}

.rightDiv {
  padding-right: 50px;
  /* padding: 10px; */
  color:  #000000;               /* 文字色 */
  margin:  10px;              /* 外側の余白 */
  border-radius:  5px;        /* 角丸指定 */
  width: 60%;                 /* 幅指定 */
  flex-flow: column;
}

/* 吹き出し設定 */
.balloon {
  position: relative;
  display: inline-block;
  /* margin: 1.5em 0; */
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #e0edff;
  border-radius: 15px;
}

.balloon:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top: 15px solid #e0edff;
}

.balloon p {
  margin: 0;
  padding: 0;
}

.balloontext {
  color: red;
}
/* ボタンをセンター */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: crimson;
  border: none;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 4px;
  cursor: pointer;
  border-radius: 5px
}

.kawakami {
  border-radius:50%;
}

.text3 {
  font-size: 50px;
  display: inline;
}

.kawakami_group {
  display: flex;
  align-items: center;
}

.kawakamitext {
  padding-left: 50px;
  font-size: 20px;
}

.graph {
  display: flex;
}

.graphtext {
  display: flex;
  flex-flow: column;
}


.HomeButton {
  width: 500px;
  height: 50px;
  border-radius: 5px;
}

.CaptureButton {
  width: 500px;
  height: 50px;
  border-radius: 5px;
} 

.RecommendBookButton {
  width: 500px;
  height: 50px;
  border-radius: 5px;
}

.buttontext {
  font-size: 30px;
}

.text2 {
  font-size: 20px;
  margin: 0px;
}
.righterrorDiv {
  padding-right: 50px;
  color: #000000;               /* 文字色 */
  margin:  10px;              /* 外側の余白 */
  border-radius:  5px;        /* 角丸指定 */
  width: 60%;                 /* 幅指定 */
  flex-flow: column;
  display: flex;
  flex-flow: column;
  justify-content:  flex-start;
  align-items: center;
}



.capturetext1 {
  font-size: 15px;
}

.capturetext2 {
  font-size: 15px;
}

.capturetext3 {
  font-size: 15px;
}

.capturetext4 {
  color: red;
  font-size: 15px;
}

.errortext1 {
  font-size: 15px;
}

.errortext2 {
  font-size: 15px;
}

.errortext3 {
  font-size: 30px;
}

.errortext4 {
  font-size: 30px;
}

.recommendbooktext1 {
    font-size: 15px;
}

.recommendbooktext2 {
  font-size: 15px;
}

.booklevepagetext1 {
  font-size: 15px;
}

.booklevepagetext2 {
  font-size: 15px;
}

p {
  display: block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.errorimage {
  text-align: center;
}

.ad {
  width: 120px;
  height: 240px;
  border: 0;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  margin-top: 10px;
  margin-bottom: 10px;
  transform-origin: 0 0;
  /* zoom: 66.6%; */
}
.select_book {
  display: flex;
  flex-flow: column;
}


.title {
  font-size: 20px;
  /* width: 120px;
  height: 240px;
  border: 0;
  overflow-y: hidden; /* Hide vertical scrollbar */
}

.auther {
  font-size: 20px;
}

.description {
  font-size: 15px;
}

iframe {
  transform: scale(0.666);
  transform-origin: 0 0;
}